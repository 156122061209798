<template>
   <ObiText v-if="domain">
      <popper
         v-if="domain.get(`info.company_name`) && domain.get(`info.description.${currentLanguage}`)"
         :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0,10px' } },
         }"
      >
         <div class="popper">
            <ObiCard>
               <template #header>
                  <div class="d-flex flex-column">
                     <h5 class="text-start text-secondary">
                        {{
                           domain.get(`info.root_domain`)
                              ? domain.get(`info.root_domain`)
                              : domain.get('info.company_name')
                        }}
                     </h5>
                     <h6 class="text-start text-primary">{{ domain.get(`info.category.${currentLanguage}`) }}</h6>
                  </div>
               </template>
               <div class="text-start mb-4">
                  <i>{{ domain.get(`info.description.${currentLanguage}`) }}</i>
                  <h5 class="text-primary mt-2">{{ $t('ABOUT_COMPANY_TITLE') }}</h5>
                  {{ domain.get(`info.about_company.${currentLanguage}`) }}
               </div>
               <div
                  class="text-start mb-2 d-flex justify-content-between align-items-center"
                  v-if="domain.get(`info.child_notice`)"
               >
                  <h5 class="text-primary">{{ $t('CHILD_ABUSE_TITLE') }}</h5>
                  <div class="d-flex child-rate">
                     <div
                        class="child-rate--item"
                        v-for="index in domain.get(`info.child_risk_rate`)"
                        :key="index"
                     ></div>
                     <div class="child-rate--average">{{ domain.get(`info.child_risk_rate`) }}/10</div>
                  </div>
               </div>
               <div class="text-start mb-2">
                  {{ domain.get(`info.child_notice.${currentLanguage}`) }}
               </div>
            </ObiCard>
         </div>
         <div slot="reference" class="d-flex align-items-center">
            <img width="20" height="20" :src="domain.get('info.favicon')" />
            <ObiText class="ms-2 text-underline">{{ domain.get('domain') }}</ObiText>
         </div>
      </popper>
      <div v-else class="d-flex align-items-center">
         <img width="20" height="20" :src="domain.get('info.favicon')" />
         <ObiText class="ms-2">{{ domain.get('domain') }}</ObiText>
      </div>
   </ObiText>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
   name: 'ObiDomainItem',
   i18n: {
      messages: {
         tr: {
            ABOUT_COMPANY_TITLE: 'Şirket Hakkında',
            CHILD_ABUSE_TITLE: 'Çocuk Uyarısı',
         },
         en: {
            ABOUT_COMPANY_TITLE: 'About the Company',
            CHILD_ABUSE_TITLE: 'Child Notice',
         },
      },
   },
   components: {
      popper: Popper,
   },
   props: {
      domain: {
         type: [String, Object],
         default: null,
      },
   },
   computed: {
      currentLanguage() {
         return this.$i18n.locale;
      },
   },
};
</script>
<style lang="scss" scoped>
.text-underline {
   text-decoration: underline;
}
.popper {
   padding: 0px !important;
   border: none !important;
   border-radius: 10px !important;
   box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.53) !important;
}
.child-rate--item {
   background-color: #dc3545;
   border: 1px solid #dc3545;
   width: 16px;
   height: 10px;
   margin: 5px;
   margin-left: 0px;
}
</style>
